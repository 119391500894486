import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import useSWR, { mutate } from 'swr'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import {
  Tooltip,
  Stack,
  CircularProgress,
  Box,
  Typography,
  Button,
} from '@mui/material'
import {
  GridActionsCellItem,
  type GridColDef,
  useGridApiRef,
} from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import BlockIcon from '@mui/icons-material/Block'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import SmallErrorIcon from '@mui/icons-material/Error'

import { DataTable, MainHeader, SubHeader } from 'components/StyledComponents'
import EmptyCustomerIcon from 'assets/icons/empty_customer.svg'
import useRoute from 'hooks/useNavigate'
import { type Customer } from 'types'
import { portalSettingState } from 'state/portalSettingStates'
import { CustomerProfileInputType, Path } from '../commonConstants'
import useApi from 'hooks/useApi'
import usePortalSetting from 'hooks/usePortalSetting'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import { type ItemBasic } from 'components/item/itemTypes'
import GridActionItem from 'components/dataGrid/GridActionItem'

const CustomersWrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
`

const CustomerListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const {
    data: customersData,
    isLoading,
    mutate: mutateCustomers,
  } = useSWR<Customer[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/customers`
      : null,
  )
  const { goTo } = useRoute()
  const dataTableRef = useGridApiRef()

  const { enqueueSnackbar } = useSnackbar()
  const { sendPostRequest } = useApi()
  const { getLocalizedContent } = usePortalSetting()
  const { checkAccesses } = useMember()

  const columns = useMemo((): GridColDef[] => {
    if (!portalSetting) {
      return []
    }

    const results: GridColDef[] = []

    if (
      portalSetting.portalInfo?.customerProfile.name !==
      CustomerProfileInputType.HIDDEN
    ) {
      results.push({
        field: 'firstName',
        headerName: formatMessage({ id: 'member_edit.label.first_name' }),
        flex: 1,
        valueGetter: (value, row) => row.firstName,
        renderCell: (params) => {
          return params.row.userPaired ? (
            params.row.firstName
          ) : (
            <Stack direction="row" spacing={1}>
              <Tooltip
                title={formatMessage({ id: 'member_edit.error.user_removed' })}
              >
                <SmallErrorIcon fontSize="small" color="error" />
              </Tooltip>

              {params.row.firstName}
            </Stack>
          )
        },
      })
      results.push({
        field: 'lastName',
        headerName: formatMessage({ id: 'member_edit.label.last_name' }),
        flex: 1,
        renderCell: (params) => params.row.lastName,
      })
    }

    results.push(
      {
        field: 'contacts',
        headerName: formatMessage({ id: 'customer_list.label.contacts' }),
        flex: 3,
        valueGetter: (_, row) => `${row.email}, ${row.phone}`,
        renderCell: (params) => (
          <Stack>
            {params.row.email && (
              <Typography variant="body2">{params.row.email}</Typography>
            )}
            {params.row.phone && (
              <Typography variant="body2">{params.row.phone}</Typography>
            )}
          </Stack>
        ),
      },
      {
        field: 'items',
        headerName: formatMessage({ id: 'customer_list.label.items' }),
        flex: 3,
        valueGetter: (_, row) =>
          row.items
            .map((item: ItemBasic) => getLocalizedContent(item.names))
            .join(', '),
        renderCell: (params) =>
          params.row.items
            .map((item: ItemBasic) => getLocalizedContent(item.names))
            .join(', '),
      },
    )

    if (checkAccesses({ [PortalSection.CUSTOMERS]: [FeatureAccess.WRITE] })) {
      results.push({
        field: 'action',
        type: 'actions',
        headerName: formatMessage({ id: 'member_list.label.actions' }),
        getActions: (params) => {
          const actions = []

          if (params.row._operations.canEdit) {
            actions.push(
              <GridActionsCellItem
                key={params.id}
                icon={<EditIcon />}
                label={formatMessage({
                  id: 'general.icon_button.edit',
                })}
                closeMenuOnClick={false}
                onClick={() => {
                  handleRowClick(params.row)
                }}
                showInMenu
              />,
            )
          }

          if (params.row._operations.canDeactivate) {
            actions.push(
              <GridActionItem
                key={params.id}
                icon={<BlockIcon />}
                label={formatMessage({
                  id: 'member_list.menu.deactivate',
                })}
                confirmDialogTitle={formatMessage({
                  id: 'customer_list.deactivate_confirm.title',
                })}
                confirmDialogContent={formatMessage({
                  id: 'customer_list.deactivate_confirm.content',
                })}
                confirmButtonText={formatMessage({
                  id: 'member_list.menu.deactivate',
                })}
                onConfirm={async (): Promise<void> => {
                  await handleConfirmedUpdateActive(params.row.id, false)
                }}
                closeMenuOnClick={false}
                showInMenu
              />,
            )
          }

          if (params.row._operations.canActivate) {
            actions.push(
              <GridActionsCellItem
                key={params.id}
                icon={<CheckIcon />}
                label={formatMessage({
                  id: 'member_list.menu.activate',
                })}
                closeMenuOnClick={false}
                onClick={() => {
                  void handleConfirmedUpdateActive(params.row.id, true)
                }}
                showInMenu
              />,
            )
          }
          return actions
        },
      })
    }

    return results
  }, [portalSetting])

  const handleRowClick = useCallback(
    (row: Customer): void => {
      if (
        checkAccesses({ [PortalSection.CUSTOMERS]: [FeatureAccess.WRITE] }) &&
        portalSetting
      ) {
        void mutate(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/customers/${row.id}`,
          row,
          false,
        )

        goTo(`${Path.CUSTOMERS_EDIT}/${row.id}`)
      }
    },
    [portalSetting],
  )

  const handleConfirmedUpdateActive = useCallback(
    async (customerId: string, active: boolean): Promise<void> => {
      if (portalSetting) {
        try {
          await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/customers/${customerId}:${active ? 'activate' : 'deactivate'}`,
          )

          if (customersData?.length) {
            const copyData = [...customersData]
            const targetIndex = copyData.findIndex(
              (data) => data.id === customerId,
            )
            copyData[targetIndex].active = active

            await mutateCustomers(copyData)
          }

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })
        } catch (error) {
          console.error(error)
        }
      }
    },
    [portalSetting, customersData],
  )

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'customer_list.header' })}
          </MainHeader>
        </Box>

        {customersData &&
          customersData?.length > 0 &&
          checkAccesses({
            [PortalSection.CUSTOMERS]: [FeatureAccess.WRITE],
          }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.CUSTOMERS_ADD)
              }}
              color="secondary"
            >
              {formatMessage({
                id: 'customer_list.button.create_customer',
              })}
            </Button>
          )}
      </Stack>

      {customersData?.length === 0 &&
        checkAccesses({ [PortalSection.CUSTOMERS]: [FeatureAccess.WRITE] }) && (
          <CustomersWrapper
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Stack textAlign="center" spacing={2} alignItems="center">
              <EmptyCustomerIcon />
              <Box width="100%">
                <SubHeader>
                  {formatMessage({
                    id: 'customer_list.label.start_creating_item',
                  })}
                </SubHeader>
              </Box>
              <Box width="100%">
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    goTo(Path.CUSTOMERS_ADD)
                  }}
                  color="secondary"
                >
                  {formatMessage({
                    id: 'customer_list.button.create_customer',
                  })}
                </Button>
              </Box>
            </Stack>
          </CustomersWrapper>
        )}

      <Box flexGrow={1} overflow="hidden">
        {isLoading && <CircularProgress />}

        {!!customersData?.length && !isLoading && (
          <DataTable
            apiRef={dataTableRef}
            rows={customersData ?? []}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: 'firstName', sort: 'asc' }],
              },
            }}
            onRowClick={(param) => {
              handleRowClick(param.row)
            }}
            getRowHeight={() => 'auto'}
            getRowClassName={(params) =>
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `${params.row.active ? 'active' : 'inactive'}`
            }
            hideFooter
            slotProps={{
              row: {
                tabIndex: 0,
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    const row = event.target as HTMLTableRowElement
                    row.click()
                  }
                },
              },
            }}
          />
        )}
      </Box>
    </Stack>
  )
}

export default CustomerListPage
