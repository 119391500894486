import { useCallback, useLayoutEffect, useState, type MouseEvent } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import CaseIcon from '@mui/icons-material/AssignmentOutlined'
import CategoryIcon from '@mui/icons-material/Category'
import PortalListIcon from '@mui/icons-material/HomeWork'
import ItemIcon from '@mui/icons-material/FolderCopy'
import SettingsIcon from '@mui/icons-material/Settings'
import MemberIcon from '@mui/icons-material/Badge'
import RoleIcon from '@mui/icons-material/Groups'
import CustomerIcon from '@mui/icons-material/Person'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MuiAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Tooltip from '@mui/material/Tooltip'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AlertIcon from '@mui/icons-material/Report'
import PollIcon from '@mui/icons-material/BarChart'
import CommentTemplatesIcon from '@mui/icons-material/Comment'
import PeopleManagementIcon from '@mui/icons-material/PeopleAlt'
import PortalManagementIcon from '@mui/icons-material/Home'

import useRoute from '../hooks/useNavigate'
import { portalSettingState } from 'state/portalSettingStates'
import { screenSizeState } from 'state/layoutStates'
import {
  MenuMode,
  PATH_ACCESSES,
  PREPORTI_SUPPORT_LOCALES_MAP,
  Path,
  SMALL_SCREEN_SIZE,
} from '../commonConstants'
import useMember from 'hooks/useMember'
import { userAccessState } from 'state/userStates'
import {
  ForwardingContactIcon,
  TeamIcon,
  NewsIcon,
  ShownMenuIcon,
  HiddenMenuIcon,
  CaseManagementIcon,
  EportiSupportIcon,
  TaskTemplateIcon,
  CollaborationIcon,
  WorkflowIcon,
} from 'components/icons/Icons'

export const MENU_FULL_WIDTH = 300
export const MENU_FOLDED_WIDTH = 90

export const MenuWrapper = styled(Stack)`
  box-shadow: 0px 10px 30px 0px rgba(17, 38, 146, 0.05);
  background: ${({ theme }) => theme.palette.background.paper};
  border-top-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  height: 100%;
  padding: 8px;

  & .MuiList-root {
    & .MuiMenuItem-root {
      padding: 13px;
      border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
      gap: 14px;

      :hover {
        background: ${({ theme }) => theme.palette.primary.light};
      }

      & .MuiListItemText-primary {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        white-space: normal;
      }

      & svg {
        color: ${({ theme }) => theme.palette.text.primary};
      }
    }

    & .MuiListItemIcon-root {
      min-width: 20px;
    }

    & .Mui-selected,
    & .Mui-selected:hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.primary.contrastText};

      & svg {
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }
    }
  }
`

const HideMenuButton = styled(Button)`
  font-size: 12px;
  font-weight: 400;
`

const Accordion = styled(MuiAccordion)`
  border: none;
  box-shadow: none;
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  &.Mui-expanded {
    background-color: ${({ theme }) => theme.palette.info.light};
  }

  &:before {
    display: 'none';
    background-color: transparent;
    top: 0px;
  }

  &:not(:first-of-type) {
    margin-top: 12px;
  }

  &:first-of-type,
  &:last-of-type {
    border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  }

  .MuiAccordionSummary-root,
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 0;
  }

  .MuiAccordionSummary-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    margin: 14px 0px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
  }

  .MuiAccordionDetails-root {
    padding: 0 14px;

    .MuiListItemText-root .MuiListItemText-primary {
      font-size: 12px;
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    background: transparent;
    color: inherit;
  }
`

const MainMenu: React.FC = () => {
  const { goTo } = useRoute()
  const location = useLocation()
  const { formatMessage, locale } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const [isOpen, setIsOpen] = useState(true)
  const { checkAccesses } = useMember()
  const userAccess = useRecoilValue(userAccessState)
  const screenSize = useRecoilValue(screenSizeState)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openSupport = Boolean(anchorEl)
  const [openedMenus, setOpenedMenus] = useState<Set<string>>(
    new Set([
      'caseManagement',
      'peopleManagement',
      'collaborationEngagement',
      'portalManagement',
    ]),
  )

  useLayoutEffect(() => {
    const newOpenedMenus = new Set<string>(openedMenus)
    const caseManagement = localStorage.getItem('caseManagement')
    if (caseManagement === MenuMode.CLOSED) {
      newOpenedMenus.delete('caseManagement')
    }

    const peopleManagement = localStorage.getItem('peopleManagement')
    if (peopleManagement === MenuMode.CLOSED) {
      newOpenedMenus.delete('peopleManagement')
    }

    const collaborationEngagement = localStorage.getItem(
      'collaborationEngagement',
    )
    if (collaborationEngagement === MenuMode.CLOSED) {
      newOpenedMenus.delete('collaborationEngagement')
    }

    const portalManagement = localStorage.getItem('portalManagement')
    if (portalManagement === MenuMode.CLOSED) {
      newOpenedMenus.delete('portalManagement')
    }

    setOpenedMenus(newOpenedMenus)

    const isMenuOpen = localStorage.getItem('isMenuOpen')
    if (isMenuOpen === MenuMode.CLOSED) {
      setIsOpen(false)
    }
  }, [])

  const handleOpenSupportMenu = useCallback(
    (event: MouseEvent<HTMLElement>): void => {
      setAnchorEl(event.currentTarget)
    },
    [],
  )

  const handleCloseSupportMenu = useCallback((): void => {
    setAnchorEl(null)
  }, [])

  const toggleMenu = (): void => {
    setIsOpen(!isOpen)
    localStorage.setItem(
      'isMenuOpen',
      !isOpen ? MenuMode.OPENED : MenuMode.CLOSED,
    )
  }

  useLayoutEffect(() => {
    if (screenSize.width < SMALL_SCREEN_SIZE) {
      setIsOpen(false)
    }
  }, [screenSize])

  const handleChange =
    (menu: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      const newOpenedMenus = new Set<string>(openedMenus)
      if (isExpanded) {
        newOpenedMenus.add(menu)
      } else {
        newOpenedMenus.delete(menu)
      }
      setOpenedMenus(newOpenedMenus)
      localStorage.setItem(menu, isExpanded ? MenuMode.OPENED : MenuMode.CLOSED)
    }

  return (
    <MenuWrapper width={isOpen ? MENU_FULL_WIDTH : MENU_FOLDED_WIDTH}>
      <Stack height={'100%'}>
        <Box paddingX={1}>
          {isOpen && (
            <HideMenuButton
              onClick={toggleMenu}
              startIcon={
                <ShownMenuIcon
                  aria-label={formatMessage({
                    id: 'main_menu.hide_menu',
                  })}
                />
              }
            >
              {formatMessage({
                id: 'main_menu.hide_menu',
              })}
            </HideMenuButton>
          )}

          {!isOpen && (
            <Tooltip
              title={formatMessage({
                id: 'main_menu.show_menu',
              })}
              placement="right"
              arrow
            >
              <IconButton
                size="small"
                onClick={toggleMenu}
                color="primary"
                sx={{ marginLeft: 1.3 }}
              >
                <HiddenMenuIcon
                  fontSize="small"
                  aria-label={formatMessage({
                    id: 'main_menu.show_menu',
                  })}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Stack flex={1} overflow="auto">
          {portalSetting && (
            <>
              <Box minWidth={MENU_FOLDED_WIDTH - 16}>
                {checkAccesses(PATH_ACCESSES[Path.CASES_LIST]) && (
                  <MenuList>
                    <MenuItem
                      selected={
                        location.pathname.includes('cases') ||
                        location.pathname === '/'
                      }
                      onClick={(): void => {
                        goTo(Path.CASES_LIST)
                      }}
                      tabIndex={0}
                      aria-label={formatMessage({
                        id: 'main_menu.cases',
                      })}
                    >
                      {isOpen && (
                        <>
                          <ListItemIcon sx={{ paddingLeft: 0 }}>
                            <CaseIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>
                            {formatMessage({ id: 'main_menu.cases' })}
                          </ListItemText>
                        </>
                      )}

                      {!isOpen && (
                        <ListItemIcon sx={{ paddingLeft: 1.3 }}>
                          <Tooltip
                            title={formatMessage({ id: 'main_menu.cases' })}
                            placement="right"
                            arrow
                          >
                            <CaseIcon fontSize="small" />
                          </Tooltip>
                        </ListItemIcon>
                      )}
                    </MenuItem>
                  </MenuList>
                )}

                {(checkAccesses(PATH_ACCESSES[Path.CONTACTS_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.TASK_TEMPLATES_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.COMMENT_TEMPLATES_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.CATEGORIES_LIST])) && (
                  <Accordion
                    onChange={handleChange('caseManagement')}
                    expanded={openedMenus.has('caseManagement')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize="small" />}
                      aria-controls="case-management-content"
                      id="case-management-header"
                      aria-label={formatMessage({
                        id: 'main_menu.case_management',
                      })}
                    >
                      {isOpen && (
                        <>
                          <CaseManagementIcon fontSize="small" />
                          {formatMessage({ id: 'main_menu.case_management' })}
                        </>
                      )}
                      {!isOpen && (
                        <Tooltip
                          title={formatMessage({
                            id: 'main_menu.case_management',
                          })}
                          placement="right"
                          arrow
                        >
                          <CaseManagementIcon fontSize="small" />
                        </Tooltip>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <MenuList>
                        {checkAccesses(PATH_ACCESSES[Path.CONTACTS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.CONTACTS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.CONTACTS_LIST)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'contact_list.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <ForwardingContactIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({ id: 'contact_list.header' })}
                                </ListItemText>
                              </>
                            )}

                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'contact_list.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <ForwardingContactIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(
                          PATH_ACCESSES[Path.TASK_TEMPLATES_LIST],
                        ) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.TASK_TEMPLATES_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.TASK_TEMPLATES_LIST)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'task_template_list.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <TaskTemplateIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({
                                    id: 'task_template_list.header',
                                  })}
                                </ListItemText>
                              </>
                            )}

                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'task_template_list.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <TaskTemplateIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(
                          PATH_ACCESSES[Path.COMMENT_TEMPLATES_LIST],
                        ) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.COMMENT_TEMPLATES_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.COMMENT_TEMPLATES_LIST)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'comment_template_list.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <CommentTemplatesIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({
                                    id: 'comment_template_list.header',
                                  })}
                                </ListItemText>
                              </>
                            )}

                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'comment_template_list.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <CommentTemplatesIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.CATEGORIES_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.CATEGORIES_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.CATEGORIES_LIST)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'edit_categories.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <CategoryIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({
                                    id: 'edit_categories.header',
                                  })}
                                </ListItemText>
                              </>
                            )}

                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'edit_categories.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <CategoryIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.WORKFLOWS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.WORKFLOWS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.WORKFLOWS_LIST)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'workflow_list.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <WorkflowIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({
                                    id: 'workflow_list.header',
                                  })}
                                </ListItemText>
                              </>
                            )}

                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'workflow_list.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <WorkflowIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}
                      </MenuList>
                    </AccordionDetails>
                  </Accordion>
                )}

                {(checkAccesses(PATH_ACCESSES[Path.MEMBERS_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.TEAMS_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.CUSTOMERS_LIST])) && (
                  <Accordion
                    onChange={handleChange('peopleManagement')}
                    expanded={openedMenus.has('peopleManagement')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize="small" />}
                      aria-controls="people-management-content"
                      id="people-management-header"
                      aria-label={formatMessage({
                        id: 'main_menu.people_management',
                      })}
                    >
                      {isOpen && (
                        <>
                          <PeopleManagementIcon fontSize="small" />
                          {formatMessage({ id: 'main_menu.people_management' })}
                        </>
                      )}
                      {!isOpen && (
                        <Tooltip
                          title={formatMessage({
                            id: 'main_menu.people_management',
                          })}
                          placement="right"
                          arrow
                        >
                          <PeopleManagementIcon fontSize="small" />
                        </Tooltip>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <MenuList>
                        {checkAccesses(PATH_ACCESSES[Path.MEMBERS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.MEMBERS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.MEMBERS_LIST)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'member_list.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <MemberIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({ id: 'member_list.header' })}
                                </ListItemText>
                              </>
                            )}

                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'member_list.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <MemberIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.TEAMS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.TEAMS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.TEAMS_LIST)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'team_list.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <TeamIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({ id: 'team_list.header' })}
                                </ListItemText>
                              </>
                            )}
                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'team_list.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <TeamIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.CUSTOMERS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.CUSTOMERS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.CUSTOMERS_LIST)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'customer_list.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <CustomerIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({
                                    id: 'customer_list.header',
                                  })}
                                </ListItemText>
                              </>
                            )}
                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'customer_list.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <CustomerIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}
                      </MenuList>
                    </AccordionDetails>
                  </Accordion>
                )}

                {(checkAccesses(PATH_ACCESSES[Path.POLLS_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.NEWS_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.ALERTS_LIST])) && (
                  <Accordion
                    onChange={handleChange('collaborationEngagement')}
                    expanded={openedMenus.has('collaborationEngagement')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize="small" />}
                      aria-controls="collaboration-engagement-content"
                      id="collaboration-engagement-header"
                      aria-label={formatMessage({
                        id: 'main_menu.collaboration_engagement',
                      })}
                    >
                      {isOpen && (
                        <>
                          <CollaborationIcon fontSize="small" />
                          {formatMessage({
                            id: 'main_menu.collaboration_engagement',
                          })}
                        </>
                      )}
                      {!isOpen && (
                        <Tooltip
                          title={formatMessage({
                            id: 'main_menu.collaboration_engagement',
                          })}
                          placement="right"
                          arrow
                        >
                          <CollaborationIcon fontSize="small" />
                        </Tooltip>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <MenuList>
                        {checkAccesses(PATH_ACCESSES[Path.POLLS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.POLLS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.POLLS_LIST)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'poll_list.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <PollIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({ id: 'poll_list.header' })}
                                </ListItemText>
                              </>
                            )}
                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'poll_list.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <PollIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.NEWS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.NEWS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.NEWS_LIST)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'news_list.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <NewsIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({ id: 'news_list.header' })}
                                </ListItemText>
                              </>
                            )}
                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'news_list.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <NewsIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.ALERTS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.ALERTS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.ALERTS_LIST)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'alert_list.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <AlertIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({ id: 'alert_list.header' })}
                                </ListItemText>
                              </>
                            )}
                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'alert_list.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <AlertIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}
                      </MenuList>
                    </AccordionDetails>
                  </Accordion>
                )}

                {(checkAccesses(PATH_ACCESSES[Path.ITEMS_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.ROLES_LIST]) ||
                  checkAccesses(PATH_ACCESSES[Path.PORTALS_EDIT])) && (
                  <Accordion
                    onChange={handleChange('portalManagement')}
                    expanded={openedMenus.has('portalManagement')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon fontSize="small" />}
                      aria-controls="portal-management-content"
                      id="portal-management-header"
                      aria-label={formatMessage({
                        id: 'main_menu.portal_management',
                      })}
                    >
                      {isOpen && (
                        <>
                          <PortalManagementIcon fontSize="small" />
                          {formatMessage({
                            id: 'main_menu.portal_management',
                          })}
                        </>
                      )}
                      {!isOpen && (
                        <Tooltip
                          title={formatMessage({
                            id: 'main_menu.portal_management',
                          })}
                          placement="right"
                          arrow
                        >
                          <PortalManagementIcon fontSize="small" />
                        </Tooltip>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <MenuList>
                        {checkAccesses(PATH_ACCESSES[Path.PORTALS_EDIT]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.PORTALS_EDIT,
                            )}
                            onClick={(): void => {
                              goTo(`${Path.PORTALS_EDIT}/${portalSetting.id}`)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'main_menu.general_settings',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <SettingsIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({
                                    id: 'main_menu.general_settings',
                                  })}
                                </ListItemText>
                              </>
                            )}
                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'main_menu.general_settings',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <SettingsIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.ITEMS_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.ITEMS_LIST,
                            )}
                            onClick={(): void => {
                              goTo(Path.ITEMS_LIST)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'portal_item_list.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <ItemIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({
                                    id: 'portal_item_list.header',
                                  })}
                                </ListItemText>
                              </>
                            )}
                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'portal_item_list.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <ItemIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}

                        {checkAccesses(PATH_ACCESSES[Path.ROLES_LIST]) && (
                          <MenuItem
                            selected={location.pathname.includes(
                              Path.ROLES_LIST,
                            )}
                            onClick={(): void => {
                              goTo(`${Path.ROLES_LIST}/0`)
                            }}
                            tabIndex={0}
                            aria-label={formatMessage({
                              id: 'member_role_list.header',
                            })}
                          >
                            {isOpen && (
                              <>
                                <ListItemIcon>
                                  <RoleIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                  {formatMessage({
                                    id: 'member_role_list.header',
                                  })}
                                </ListItemText>
                              </>
                            )}
                            {!isOpen && (
                              <ListItemIcon>
                                <Tooltip
                                  title={formatMessage({
                                    id: 'member_role_list.header',
                                  })}
                                  placement="right"
                                  arrow
                                >
                                  <RoleIcon fontSize="small" />
                                </Tooltip>
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        )}
                      </MenuList>
                    </AccordionDetails>
                  </Accordion>
                )}
              </Box>
            </>
          )}

          <Box>
            {(userAccess?.userData.admin ||
              (userAccess?.accessiblePortals &&
                userAccess?.accessiblePortals.length > 1)) && (
              <MenuList>
                <MenuItem
                  selected={location.pathname === Path.PORTALS_LIST}
                  onClick={(): void => {
                    goTo(Path.PORTALS_LIST)
                  }}
                  tabIndex={0}
                  aria-label={formatMessage({ id: 'portal_list.header' })}
                >
                  {isOpen && (
                    <>
                      <ListItemIcon sx={{ paddingLeft: 0 }}>
                        <PortalListIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>
                        {formatMessage({ id: 'portal_list.header' })}
                      </ListItemText>
                    </>
                  )}
                  {!isOpen && (
                    <ListItemIcon sx={{ paddingLeft: 1.3 }}>
                      <Tooltip
                        title={formatMessage({
                          id: 'portal_list.header',
                        })}
                        placement="right"
                        arrow
                      >
                        <PortalListIcon fontSize="small" />
                      </Tooltip>
                    </ListItemIcon>
                  )}
                </MenuItem>
              </MenuList>
            )}
          </Box>
        </Stack>

        <Stack paddingX={1} flexDirection="column-reverse">
          <Box>
            {isOpen && (
              <Button
                id="support-button"
                aria-controls={openSupport ? 'support-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openSupport ? 'true' : undefined}
                startIcon={
                  <EportiSupportIcon
                    aria-label={formatMessage({
                      id: 'main_menu.eporti_support',
                    })}
                  />
                }
                onClick={handleOpenSupportMenu}
              >
                {formatMessage({ id: 'main_menu.eporti_support' })}
              </Button>
            )}

            {!isOpen && (
              <Tooltip
                title={formatMessage({ id: 'main_menu.eporti_support' })}
                placement="right"
                arrow
              >
                <IconButton
                  size="small"
                  onClick={handleOpenSupportMenu}
                  color="primary"
                  sx={{ marginLeft: 1.3 }}
                >
                  <EportiSupportIcon
                    fontSize="small"
                    aria-label={formatMessage({
                      id: 'main_menu.eporti_support',
                    })}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          <Menu
            id="support-menu"
            aria-labelledby="support-button"
            anchorEl={anchorEl}
            open={openSupport}
            onClose={handleCloseSupportMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                window.open(
                  `https://www.preporti.com/${
                    PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                  }faq`,
                  '_blank',
                )
                handleCloseSupportMenu()
              }}
            >
              {formatMessage({ id: 'login_footer.link.faq' })}
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.open(
                  `https://www.preporti.com/${
                    PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                  }terms-of-use`,
                  '_blank',
                )
                handleCloseSupportMenu()
              }}
            >
              {formatMessage({ id: 'login_footer.link.terms' })}
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.open(
                  `https://www.preporti.com/${
                    PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                  }privacy-policy`,
                  '_blank',
                )
                handleCloseSupportMenu()
              }}
            >
              {formatMessage({
                id: 'login_footer.link.privacy_policy',
              })}
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.open(
                  `https://www.preporti.com/${
                    PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                  }`,
                  '_blank',
                )
                handleCloseSupportMenu()
              }}
            >
              {formatMessage({ id: 'login_footer.link.about_us' })}
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </MenuWrapper>
  )
}

export default MainMenu
