import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  type MouseEvent,
} from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'
import {
  type GridColDef,
  type GridEventListener,
  type GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import Switch from '@mui/material/Switch'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'
import Button from '@mui/material/Button'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'

import {
  InfoText,
  DataTable,
  NotificationWrapper,
  SubHeader,
  StrongText,
} from '../StyledComponents'
import { type Resource } from '../../types'
import {
  casesDataState,
  columnVisibilityState,
  lastScrollPositionState,
  offsetState,
  sortModelState,
} from '../../state/caseListStates'
import { nameInitials, subWords } from '../../utils/stringUtils'
import ResourceWrapper from 'components/resource/ResourceWrapper'
import CaseStatusTag from 'components/case/CaseStatusTag'
import { AnonymousIcon, EmptyImageIcon } from 'components/icons/Icons'
import usePortalSetting from 'hooks/usePortalSetting'
import { getResourceFormat, getThumbnailUrl } from 'utils/fileUtils'
import CaseShareabilityIcon from 'components/case/CaseShareabilityIcon'
import {
  CASE_COLUMN_LABEL,
  CaseFileSource,
  CaseListColumn,
} from 'components/case/caseConstants'
import { FileType } from 'commonConstants'
import CategoryTagList from 'components/category/CategoryTagList'
import { type CaseInfo } from 'components/case/caseTypes'
import EmptyResultIcon from 'assets/icons/empty_result.svg'

const PAGE_SIZE = 20

const COLUMNS = [
  CaseListColumn.ADDRESS_AND_TITLE,
  CaseListColumn.SHAREABILITY,
  CaseListColumn.STATUS,
  CaseListColumn.CREATED_BY,
  CaseListColumn.UPDATED,
  CaseListColumn.CREATED,
]

type CaseListProps = {
  isLoading: boolean
  onCaseClick?: (data: CaseInfo) => void
  isDefaultFiltersValue: boolean
  onResetFilters: () => void
}

const ShortenTitleWrapper = styled(StrongText)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
`

const CaseList: React.FC<CaseListProps> = ({
  isLoading,
  onCaseClick,
  isDefaultFiltersValue,
  onResetFilters,
}) => {
  const { formatMessage } = useIntl()
  const dataTableRef = useGridApiRef()
  const [casesData, setCasesData] = useRecoilState(casesDataState)
  const setOffset = useSetRecoilState(offsetState)
  const [sortModel, setSortModel] = useRecoilState(sortModelState)
  const [lastScrollPosition, setLastScrollPosition] = useRecoilState(
    lastScrollPositionState,
  )

  const lastLoadedMoreRowIndex = useRef(-1)
  const { formatDate } = usePortalSetting()
  const [columnsVisibilityEl, setColumnsVisibilityEl] =
    useState<null | HTMLElement>(null)
  const openColumnsVisibilityMenu = Boolean(columnsVisibilityEl)
  const [columnVisibility, setColumnVisibility] = useRecoilState(
    columnVisibilityState,
  )

  const columns: GridColDef[] = [
    {
      field: CaseListColumn.IMAGE,
      headerName: '',
      width: 100,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      display: 'flex',
      renderHeader: () => (
        <IconButton
          id="column-visibility-button"
          aria-controls={
            openColumnsVisibilityMenu ? 'column-visibility-menu' : undefined
          }
          aria-haspopup="true"
          aria-expanded={openColumnsVisibilityMenu ? 'true' : undefined}
          onClick={handleOpenColumnsVisibilityMenu}
          size="small"
          aria-label={formatMessage({
            id: 'case_detail.button.columns_visibility',
          })}
        >
          <ViewColumnIcon fontSize="inherit" color="primary" />
        </IconButton>
      ),
      renderCell: (params) => {
        const notificationElement = !params.row.commentsResolved ? (
          <NotificationWrapper position="absolute">!</NotificationWrapper>
        ) : null
        const { resources } = params.row
        const reportResources = resources?.filter(
          (resource: Resource) =>
            resource.source === CaseFileSource.REPORT_UPLOAD,
        )
        const resourcesElement = reportResources?.length ? (
          <ResourceWrapper
            size={70}
            url={reportResources[0].uri}
            format={getResourceFormat(reportResources[0])}
            imageSize="cover"
          />
        ) : (
          <ResourceWrapper
            size={70}
            format={FileType.IMAGE}
            imageSize="cover"
            hasBorder={false}
          >
            <EmptyImageIcon sx={{ width: 60, height: 49 }} />
          </ResourceWrapper>
        )

        return (
          <>
            {notificationElement}
            {resourcesElement}
          </>
        )
      },
    },
    {
      field: CaseListColumn.ADDRESS_AND_TITLE,
      headerName: formatMessage({
        id: CASE_COLUMN_LABEL[CaseListColumn.ADDRESS_AND_TITLE],
      }),
      flex: 3,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      display: 'flex',
      renderCell: (params) => (
        <Stack>
          <ShortenTitleWrapper>
            {params.row.title || subWords(params.row.description, 15)}
          </ShortenTitleWrapper>
          <Stack direction="row">
            <InfoText>
              <LocationOnIcon sx={{ fontSize: 16 }} />
              {params.row.location.address}
            </InfoText>
          </Stack>
          <Box overflow="hidden">
            <CategoryTagList category={params.row.category} />
          </Box>
        </Stack>
      ),
    },
    {
      field: CaseListColumn.SHAREABILITY,
      headerName: formatMessage({
        id: CASE_COLUMN_LABEL[CaseListColumn.SHAREABILITY],
      }),
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      display: 'flex',
      renderCell: (params) => (
        <CaseShareabilityIcon shareability={params.row.shareability} />
      ),
    },
    {
      field: CaseListColumn.STATUS,
      headerName: formatMessage({
        id: CASE_COLUMN_LABEL[CaseListColumn.STATUS],
      }),
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      display: 'flex',
      renderCell: (params) => <CaseStatusTag status={params.value} />,
    },
    {
      field: CaseListColumn.CREATED_BY,
      headerName: formatMessage({
        id: CASE_COLUMN_LABEL[CaseListColumn.CREATED_BY],
      }),
      flex: 2,
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      display: 'flex',
      renderCell: (params) => (
        <CardHeader
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
          }}
          avatar={
            <Avatar
              src={getThumbnailUrl(params.row.reporter?.user?.avatarUrl)}
              sx={{ width: 30, height: 30, fontSize: 16 }}
              alt={
                params.row.reporter?.fullName ||
                params.row.reporter?.user?.fullName
              }
            >
              {params.row.anonymous ? (
                <AnonymousIcon />
              ) : (
                nameInitials(params.row.reporter?.fullName) ||
                nameInitials(params.row.reporter?.user?.fullName)
              )}
            </Avatar>
          }
          title={
            params.row.anonymous
              ? formatMessage({ id: 'case_detail.label.anonymous' })
              : params.row.reporter?.fullName?.trim() ||
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                `${params.row.reporter?.user?.firstName} ${params.row.reporter?.user?.lastName}`
          }
          subheader={
            !params.row.anonymous && (
              <InfoText>
                {params.row.reporter?.email || params.row.reporter?.user?.email}
              </InfoText>
            )
          }
        />
      ),
    },
    {
      field: CaseListColumn.UPDATED,
      headerName: formatMessage({
        id: CASE_COLUMN_LABEL[CaseListColumn.UPDATED],
      }),
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      sortingOrder: ['desc', 'asc', null],
      display: 'flex',
      renderCell: (params) => {
        const datetime = formatDate(params.value).split(' ')
        return (
          <Stack>
            <Typography variant="body1">{datetime[0]}</Typography>
            <InfoText>{datetime[1]}</InfoText>
          </Stack>
        )
      },
    },
    {
      field: CaseListColumn.CREATED,
      headerName: formatMessage({
        id: CASE_COLUMN_LABEL[CaseListColumn.CREATED],
      }),
      flex: 1,
      disableColumnMenu: true,
      filterable: false,
      sortable: true,
      display: 'flex',
      renderCell: (params) => {
        const datetime = formatDate(params.value).split(' ')
        return (
          <Stack>
            <Typography variant="body1">{datetime[0]}</Typography>
            <InfoText>{datetime[1]}</InfoText>
          </Stack>
        )
      },
    },
  ]

  const handleOpenColumnsVisibilityMenu = (
    event: MouseEvent<HTMLElement>,
  ): void => {
    setColumnsVisibilityEl(event.currentTarget)
  }

  const handleCloseColumnsVisibilityMenu = (): void => {
    setColumnsVisibilityEl(null)
  }

  const handleToggleColumnsVisibility = useCallback(
    (column: CaseListColumn): void => {
      const newVisibility = {
        ...columnVisibility,
        [column]: !columnVisibility[column],
      }
      setColumnVisibility(newVisibility)
      localStorage.setItem(
        'casesColumnVisibility',
        JSON.stringify(newVisibility),
      )
    },
    [columnVisibility],
  )

  const handleScrolling: GridEventListener<'scrollPositionChange'> =
    useCallback((params) => {
      if (dataTableRef.current) {
        const currentRowCount = dataTableRef.current.getRowsCount()
        const scrollPosition = params.renderContext?.lastRowIndex

        if (
          scrollPosition &&
          scrollPosition > 0 &&
          currentRowCount > 0 &&
          params.top > 0 &&
          scrollPosition >= currentRowCount - 1 &&
          currentRowCount > lastLoadedMoreRowIndex.current
        ) {
          console.log('load more data')
          lastLoadedMoreRowIndex.current = currentRowCount
          setOffset((prev) => prev + PAGE_SIZE)
        }
      }
    }, [])

  useLayoutEffect(() => {
    let subscriber: () => void
    if (
      !!dataTableRef.current &&
      Object.keys(dataTableRef.current).length > 0
    ) {
      subscriber = dataTableRef.current.subscribeEvent(
        'scrollPositionChange',
        handleScrolling,
      )
    }

    return () => {
      if (subscriber) {
        subscriber()
      }
    }
  }, [dataTableRef.current])

  useEffect(() => {
    if (lastScrollPosition && casesData.length && dataTableRef.current) {
      // hacky way to scroll until the scroll element loaded, can
      setTimeout(() => {
        dataTableRef.current.scroll(lastScrollPosition)
        setLastScrollPosition(null)
      }, 100)
    }
  }, [JSON.stringify(casesData)])

  const handleRowClick = useCallback((row: CaseInfo): void => {
    setLastScrollPosition(dataTableRef.current.getScrollPosition())
    if (onCaseClick) {
      onCaseClick(row)
    }
  }, [])

  const handleSortModelChange = (newSortModel: GridSortModel): void => {
    setCasesData([])

    if (newSortModel.length) {
      setSortModel(newSortModel)
    } else {
      setSortModel((prev) => {
        const prevSortModel = prev[0]
        return [
          {
            field: prevSortModel.field,
            sort: prevSortModel.sort === 'asc' ? 'desc' : 'asc',
          },
        ]
      })
    }
    setOffset(0)
    setLastScrollPosition(null)
    lastLoadedMoreRowIndex.current = -1

    localStorage.setItem('casesSortModel', JSON.stringify(newSortModel))
  }

  if (!casesData?.length && !isLoading && !isDefaultFiltersValue) {
    return (
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Stack textAlign="center" spacing={2} alignItems="center">
          <EmptyResultIcon />
          <Box width="100%">
            <SubHeader>
              {formatMessage({
                id: 'case_list_map.label.case_empty',
              })}
            </SubHeader>
            <Typography variant="body2">
              {formatMessage({
                id: 'case_list.label.clear_filtering',
              })}
            </Typography>
          </Box>
          <Box width="100%">
            <Button
              variant="outlined"
              size="small"
              onClick={onResetFilters}
              data-testid="reset-filters-button"
            >
              {formatMessage({ id: 'case_list.button.reset_filters' })}
            </Button>
          </Box>
        </Stack>
      </Stack>
    )
  }

  return (
    <>
      <DataTable
        rowHeight={80}
        data-testid="case-list"
        apiRef={dataTableRef}
        loading={isLoading}
        rows={casesData}
        columns={columns}
        columnVisibilityModel={columnVisibility}
        sortingOrder={['desc', 'asc']}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onRowClick={(param) => {
          handleRowClick(param.row)
        }}
        getRowHeight={() => 'auto'}
        slotProps={{
          row: {
            tabIndex: 0,
            onKeyDown: (event) => {
              if (event.key === 'Enter') {
                const row = event.target as HTMLTableRowElement
                row.click()
              }
            },
          },
        }}
        hideFooter
        hideFooterSelectedRowCount
        sx={{
          '& .MuiDataGrid-row': {
            position: 'relative',
          },
        }}
      />

      <Menu
        id="column-visibility-menu"
        MenuListProps={{
          'aria-labelledby': 'column-visibility-button',
        }}
        anchorEl={columnsVisibilityEl}
        open={openColumnsVisibilityMenu}
        onClose={handleCloseColumnsVisibilityMenu}
        TransitionComponent={Fade}
      >
        {COLUMNS.map((column) => (
          <MenuItem
            key={column}
            value={column}
            onClick={() => {
              handleToggleColumnsVisibility(column)
            }}
          >
            <Switch checked={columnVisibility[column]} size="small" />
            <Typography marginLeft={1}>
              {formatMessage({
                id: CASE_COLUMN_LABEL[column],
              })}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default CaseList
