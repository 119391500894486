import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import useSWR, { mutate } from 'swr'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import {
  GridActionsCellItem,
  type GridColDef,
  useGridApiRef,
} from '@mui/x-data-grid'
import {
  Stack,
  CircularProgress,
  Box,
  Button,
  Tooltip,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import EditIcon from '@mui/icons-material/Edit'

import { DataTable, MainHeader, SubHeader } from 'components/StyledComponents'
import { portalSettingState } from 'state/portalSettingStates'
import useApi from 'hooks/useApi'
import { type ItemBasic } from 'components/item/itemTypes'
import usePortalSetting from 'hooks/usePortalSetting'
import { Path } from 'commonConstants'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import useRoute from 'hooks/useNavigate'
import { type CommentTemplate } from 'components/commentTemplate/commentTemplateTypes'
import EmptyTemplateIcon from 'assets/icons/empty_comment_template.svg'
import GridActionItem from 'components/dataGrid/GridActionItem'

const Wrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  position: relative;
`

const CommentTemplateListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const dataTableRef = useGridApiRef()
  const { goTo } = useRoute()
  const { checkAccesses } = useMember()
  const { getLocalizedContent } = usePortalSetting()
  const portalSetting = useRecoilValue(portalSettingState)
  const { sendDeleteRequest } = useApi()

  const {
    data: templatesData,
    isLoading,
    mutate: mutateTemplates,
  } = useSWR<CommentTemplate[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/templates/comments`
      : null,
  )

  const handleRowClick = useCallback(
    (row: CommentTemplate): void => {
      if (
        checkAccesses({
          [PortalSection.COMMENT_TEMPLATES]: [FeatureAccess.WRITE],
        }) &&
        portalSetting
      ) {
        void mutate(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/templates/comments/${row.id}`,
          row,
          false,
        )

        goTo(`${Path.COMMENT_TEMPLATES_EDIT}/${row.id}`)
      }
    },
    [portalSetting],
  )

  const columns = useMemo((): GridColDef[] => {
    if (!portalSetting) {
      return []
    }

    const columnsData: GridColDef[] = [
      {
        field: 'name',
        headerName: formatMessage({ id: 'comment_template_list.label.name' }),
        flex: 1,
        valueGetter: (value, row) => getLocalizedContent(row.names),
        renderCell: (params) => getLocalizedContent(params.row.names),
      },

      {
        field: 'items',
        headerName: formatMessage({ id: 'comment_template_list.label.items' }),
        flex: 2,
        valueGetter: (value, row) =>
          row.items
            .map((item: ItemBasic) => getLocalizedContent(item.names))
            .join(', '),
        renderCell: (params) =>
          params.row.items
            .map((item: ItemBasic) => getLocalizedContent(item.names))
            .join(', '),
      },
    ]

    if (
      checkAccesses({
        [PortalSection.COMMENT_TEMPLATES]: [FeatureAccess.WRITE],
      })
    ) {
      columnsData.push({
        field: 'action',
        type: 'actions',
        getActions: (params) => {
          const actions = []

          if (params.row._operations.canEdit) {
            actions.push(
              <GridActionsCellItem
                key={params.id}
                icon={<EditIcon />}
                label={formatMessage({
                  id: 'general.icon_button.edit',
                })}
                closeMenuOnClick={false}
                onClick={() => {
                  handleRowClick(params.row)
                }}
                showInMenu
              />,
            )
          }

          if (params.row._operations.canDelete) {
            actions.push(
              <GridActionItem
                key={params.id}
                icon={<DeleteIcon />}
                label={formatMessage({
                  id: 'general.icon_button.delete',
                })}
                confirmDialogTitle={formatMessage({
                  id: 'comment_template_list.dialog.delete.title',
                })}
                confirmDialogContent={formatMessage({
                  id: 'comment_template_list.dialog.delete.content',
                })}
                confirmButtonText={formatMessage({
                  id: 'general.icon_button.delete',
                })}
                onConfirm={async (): Promise<void> => {
                  await deleteTemplate(params.row.id)
                }}
                closeMenuOnClick={false}
                showInMenu
              />,
            )
          }

          return actions
        },
      })
    }

    return columnsData
  }, [portalSetting])

  const deleteTemplate = useCallback(
    async (templateId: string): Promise<void> => {
      if (!portalSetting) {
        return
      }

      try {
        await sendDeleteRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting?.id
          }/templates/comments/${templateId}`,
        )

        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })

        await mutateTemplates()
      } catch (error) {
        console.error(error)
      }
    },
    [portalSetting],
  )

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'comment_template_list.header' })}
            <Tooltip
              title={formatMessage({
                id: 'comment_template_list.label.creating_template_help_text',
              })}
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </MainHeader>
        </Box>

        {templatesData &&
          templatesData?.length > 0 &&
          checkAccesses({
            [PortalSection.COMMENT_TEMPLATES]: [FeatureAccess.WRITE],
          }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.COMMENT_TEMPLATES_ADD)
              }}
              color="secondary"
            >
              {formatMessage({
                id: 'comment_template_list.button.create_template',
              })}
            </Button>
          )}
      </Stack>

      <Stack flexGrow={1} overflow="auto" spacing={2}>
        {templatesData &&
          templatesData.length === 0 &&
          checkAccesses({
            [PortalSection.COMMENT_TEMPLATES]: [FeatureAccess.WRITE],
          }) && (
            <Wrapper
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Stack textAlign="center" spacing={2} alignItems="center">
                <EmptyTemplateIcon />
                <Box width="60%">
                  <SubHeader>
                    {formatMessage({
                      id: 'comment_template_list.label.start_creating_template',
                    })}
                  </SubHeader>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'comment_template_list.label.creating_template_help_text',
                    })}
                  </Typography>
                </Box>
                <Box width="100%">
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      goTo(Path.COMMENT_TEMPLATES_ADD)
                    }}
                    color="secondary"
                  >
                    {formatMessage({
                      id: 'comment_template_list.button.create_template',
                    })}
                  </Button>
                </Box>
              </Stack>
            </Wrapper>
          )}

        {isLoading && <CircularProgress />}

        {!!templatesData?.length && !isLoading && (
          <DataTable
            apiRef={dataTableRef}
            rows={templatesData ?? []}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            onRowClick={(param) => {
              handleRowClick(param.row)
            }}
            getRowHeight={() => 'auto'}
            hideFooter
            slotProps={{
              row: {
                tabIndex: 0,
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    const row = event.target as HTMLTableRowElement
                    row.click()
                  }
                },
              },
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

export default CommentTemplateListPage
